import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  label?: string
  sectionID?: string
  title?: string
}

export const ServicesList = memo(function ServicesList({
  services,
  label,
  sectionID,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }
  return (
    <Container
      id={`section-${sectionID}`}
      tag="section"
      wrap
      row
      space="between"
      dial={2}
    >
      <Text>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}
      </Text>
      {services.map((item, index) => (
        <Service variant="default" key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  margin: 12.5rem 0 0;
  padding: 9.75rem 10.556vw 0;
  align-items: flex-start;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 5.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  > div:nth-of-type(3),
  > div:nth-of-type(5) {
    top: -15rem;
  }

  @media (max-width: 1439px) {
    > div:nth-of-type(3),
    > div:nth-of-type(5) {
      top: -14.167vw;
    }
  }

  @media (min-width: 1200px) {
    &:before {
      width: calc(100% - 28.611vw);
    }

    > div:nth-of-type(2) {
      margin-top: 2.75rem;
    }

    > div:nth-of-type(4),
    > div:nth-of-type(5) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1199px) {
    margin: 6.25rem 0 5.25rem;
    padding: 4.6875rem 1.875rem 0.9375rem;

    > div:nth-of-type(3),
    > div:nth-of-type(5) {
      top: auto;
    }

    &:before {
      height: 100%;
      bottom: 0;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 1.875rem;
  }
`

const Text = styled.div`
  position: relative;
  z-index: 2;
  max-width: 41.875rem;
  padding-bottom: 5rem;
  padding-right: 3.472vw;
  width: calc(50% - 0.5rem);

  @media (max-width: 1439px) {
    padding-right: 5vw;
  }

  @media (max-width: 1199px) {
    width: 100%;
    max-width: none;
    padding-bottom: 3.25rem;
    padding-right: 0;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding-bottom: 1.875rem;
  }
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  max-width: 28.75rem;

  > div {
    margin: 1.25rem 0 0;
  }

  @media (max-width: 1199px) {
    margin: 0 auto;
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin: 1rem auto 0;
    }
  }
`
