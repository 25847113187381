import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Hotel, Props as HotelProps } from './Hotel'

export interface Props {
  description?: string
  label?: string
  hotels: HotelProps[]
  sectionID?: string
  title?: string
}

export const HotelsList = memo(function HotelsList({
  description,
  label,
  hotels,
  sectionID,
  title,
}: Props) {
  if (hotels.length < 1) {
    return null
  }

  return (
    <Container id={`section-${sectionID}`}>
      <FadeInUp>
        <IconLogo />
      </FadeInUp>

      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      <Hotels row wrap>
        {hotels.map((item, index) => (
          <Hotel key={index} {...item} />
        ))}
      </Hotels>
    </Container>
  )
})

const Container = styled.section`
  margin: 9rem 0 12.5rem;
  padding: 0 10.556vw;
  text-align: center;

  svg {
    width: 5rem;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 2.25rem;
  }

  @media (max-width: 1199px) {
    margin: 6.25rem 0 7.5rem;
    padding: 0 1.875rem;

    svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  max-width: 28.75rem;
  margin: 0 auto;

  > div {
    margin: 1.25rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1rem;
    }
  }
`

const Description = styled.div`
  max-width: 46.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin: 3.75rem auto 0;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 2.25rem;
  }
`

const Hotels = styled(FlexBox)`
  margin: 0 -5.625rem 0 0;

  > a {
    width: calc(50% - 5.625rem);
    margin: 6.375rem 5.625rem 0 0;
  }

  @media (max-width: 1023px) {
    margin: 0;

    > a {
      width: 100%;
      margin-right: 0;
    }
  }
`
