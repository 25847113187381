import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  title?: string
  variant?: Variant
}

export const Service = memo(function Service({
  cta,
  image,
  title,
  variant = 'default',
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container variant={variant}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      <Wrap className="service-title">
        {title ? <Title className="item-title">{title}</Title> : null}
        {cta ? <CTA className="item-cta" {...cta} /> : null}
      </Wrap>
    </Container>
  )
})

const Container = styled.div<ContainerProps>`
  width: calc(50% - 3.0625rem);
  margin-bottom: 6.125rem;
  padding-bottom: 35%;
  overflow: hidden;
  position: relative;
  text-align: center;

  &:before,
  &:after {
    content: '';
    backface-visibility: hidden;
    position: absolute;
    transition: 0.25s ease-in-out;
    z-index: 2;
  }
  &:before {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.fadeDark, 0)},
      ${rgba(theme.colors.variants.fadeDark, 1)}
    );
    opacity: 0.7;
    top: 0;
    left: 0;
  }
  &:after {
    border: 0.0625rem solid
      ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.4;
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 1.875rem);
    margin-bottom: 3.75rem;
    padding-bottom: 38%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 1.875rem;
    padding-bottom: 55%;
    &:before {
      opacity: 0.92;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 120%;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'simple':
        return css`
          text-align: left;
          width: calc(33.333% - 3.9375rem);
          padding-bottom: 42%;
          margin-right: 3.9375rem;
          margin-bottom: 0;

          &:after {
            content: none;
          }

          &:before {
            opacity: 0.8;
          }

          .service-title {
            left: 2.25rem;
            right: 2.25rem;
            bottom: 2.8125rem;
          }

          .item-title {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2.3125rem;
          }
          .item-cta {
            margin-top: 0.125rem;

            &:after {
              background: ${theme.colors.variants.secondaryLight};
            }
          }

          &:hover {
            img {
              transform: scale(1.05);
            }
            .item-cta {
              color: ${theme.colors.variants.secondaryLight};
              &:after {
                right: 0.3125rem;
              }
              svg {
                margin-left: 2.625rem;
                stroke: ${theme.colors.variants.secondaryLight};
              }
            }
          }

          @media (max-width: 1199px) {
            width: calc(33.333% - 3.125rem);
            margin-right: 3.125rem;
            padding-bottom: 38%;
          }

          @media (max-width: 1023px) {
            width: 100%;
            margin: 1.5rem 0 0 0 !important;
            padding-bottom: 55%;
            &:before {
              opacity: 0.92;
            }

            img {
              transform: scale(1.05);
            }
            .item-cta {
              color: ${theme.colors.variants.secondaryLight};
              &:after {
                right: 0.3125rem;
                top: 0.625rem;
              }
              svg {
                margin-left: 2.625rem;
                stroke: ${theme.colors.variants.secondaryLight};
              }

              &:hover {
                svg {
                  transform: translate(0, 0.0625rem);
                }
              }
            }

            .service-title {
              left: 1.875rem;
              right: 1.875rem;
              bottom: 0;
            }
          }

          @media (max-width: 767px) {
            padding-bottom: 120%;
          }
        `
    }
  }}
`

const Wrap = styled.div`
  position: absolute;
  bottom: 3.375rem;
  right: 2.5rem;
  left: 2.5rem;
  transition: 0.25s ease-in-out;
  z-index: 3;

  @media (max-width: 1023px) {
    transform: translateY(-1.75rem);
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.3125rem;
`

const CTA = styled(Button)``

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'simple'
