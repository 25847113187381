import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  description?: string
  image?: ImageProps
  languageCode: string
  title: string
  URL?: string
}

export const Hotel = memo(function Hotel({
  className,
  description,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container className={className} to={URL} target="_blank">
      <Background className="item-bg">
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper>
        <Title className="item-title" row>
          {title}
        </Title>

        {description ? (
          <Description
            className="item-desc"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}

        <CTA className="item-cta" dial={4} inline row>
          {useVocabularyData('visit-website', languageCode)}
          <ChevronRight />
        </CTA>
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  position: relative;
  text-align: left;

  &:hover {
    &:before {
      left: 1.875rem;
    }
    .item-bg {
      img {
        transform: scale(1.1);
      }
    }
    .item-title {
      &:before {
        width: 3.75rem;
      }
    }
    .item-desc {
      transform: translateX(1.75rem);
    }
    .item-cta {
      transform: translateX(1.75rem);
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
      &:after {
        right: 0.3125rem;
      }
      svg {
        margin-left: 2.625rem;
        stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
      }
    }
  }

  @media (max-width: 1023px) {
    .item-cta {
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
      &:after {
        right: 0.3125rem;
      }
      svg {
        margin-left: 2.625rem;
        stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
      }
    }
  }

  .item-cta svg {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 1.875rem;
    right: -1.875rem;
    left: 50%;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }

  @media (max-width: 767px) {
    &:before {
      right: -1.25rem;
    }
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 70%;
  position: relative;

  img {
    width: 101%;
    height: 101%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    padding-bottom: 33%;
  }

  @media (max-width: 767px) {
    padding-bottom: 50%;
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  padding: 2rem 2rem 0.625rem 3rem;
  position: relative;
`

const Title = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  transform: translateX(-3rem);
  transition: 0.3s ease-in-out;
  &:before {
    content: '';
    width: 2rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    opacity: 0.4;
    margin-right: 1rem;
    transform: translateY(0.875rem);
    transition: 0.3s ease-in-out;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 1.25rem;
  transition: 0.3s ease-in-out;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

const CTA = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: 2.125rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0.625rem;
    right: 1.5625rem;
    left: calc(100% - 1.5625rem);
    transition: 0.3s 0.15s ease-in-out;
  }

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
    margin-left: 2.1875rem;
    transition: 0.3s ease-in-out;
    transform: translateY(0.0625rem);
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

export type Variant = 'default' | 'simple'
