import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  label: string
  xAxis: string
  yAxis: string
}

export const Poi = memo(function Poi({ label, xAxis, yAxis }: Props) {
  return (
    <Container
      style={{
        top: `${yAxis.replace(',', '.')}%`,
        left: `${xAxis.replace(',', '.')}%`,
      }}
    >
      <Tooltip>{label}</Tooltip>
    </Container>
  )
})

const Container = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  &:hover {
    &:before {
      animation: zoomIn 0.8s;
      opacity: 1;
    }
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      width: 0.875rem;
      height: 0.875rem;
    }

    > div {
      opacity: 1;
      transform: translate(-50%, -1.375rem);
      visibility: visible;
      z-index: 1;
    }
  }
  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-in-out;
  }
  &:before {
    width: 2.5rem;
    height: 2.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    opacity: 0.2;
  }
  &:after {
    width: 1.25rem;
    height: 1.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    transform: translate(-50%, -50%);
  }

  @keyframes zoomIn {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @media (max-width: 1199px) {
    &:before {
      width: 2.25rem;
      height: 2.25rem;
      background: none;
      border: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralDark2};
    }
    &:after {
      background: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }
`

const Tooltip = styled.div`
  height: 2.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 0.75rem;
  padding: 0.9375rem 2.25rem;
  opacity: 0;
  position: absolute;
  top: -1.875rem;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.4s ease-in-out;
  visibility: hidden;
  z-index: -1;
  white-space: nowrap;
`
