import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  Props as ServiceProps,
  Service,
} from 'app/components/ServicesList/Service'
import React, { memo } from 'react'

export interface Props {
  services: ServiceProps[]
  sectionID?: string
  title?: string
}

export const ExtraServicesList = memo(function ExtraServicesList({
  services,
  sectionID,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }
  return (
    <Container id={`section-${sectionID}`}>
      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}
      <Services row wrap>
        {services.map((item, index) => (
          <Service variant="simple" key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin: 7.25rem 0 12.5rem;
  padding: 0 10.556vw;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 6.25rem 0;
    padding: 0 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  max-width: 28.75rem;
  margin: 0 auto;

  > div {
    margin: 1.25rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1rem;
    }
  }
`

const Services = styled(FlexBox)`
  margin-top: 6.25rem;
  margin-right: -3.9375rem;

  @media (max-width: 1199px) {
    margin-right: -3.125rem;
  }

  @media (max-width: 1023px) {
    margin: 1.875rem 0 0 0;
  }
`
