import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

import { Option, Props as OptionProps } from './Option'

export interface Props {
  disabledFirst?: string
  name: string
  error?: boolean
  isDirty?: boolean
  options: OptionProps[]
  required?: boolean
  size?: 'M' | 'L'
  defaultValue?: string
  onBlur: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  onFocus?: FocusEventHandler<HTMLSelectElement>
}
export const Select = memo(
  forwardRef<HTMLSelectElement, Props>(function Select(
    {
      disabledFirst,
      name,
      error = false,
      isDirty = false,
      options,
      required,
      size = 'M',
      defaultValue,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        error={error}
        className={`type-${size}`}
        isDirty={isDirty}
        name={name}
        required={required}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      >
        {disabledFirst ? <Option value={disabledFirst} disabled /> : null}
        {options.map((item, index) => (
          <Option key={index} {...item} />
        ))}
      </Container>
    )
  }),
)

Select.displayName = 'Select'

const Container = styled.select<ContainerProps>`
  width: 100%;
  height: 2.875rem;
  background: url('/dropdown.svg') no-repeat right center;
  background-size: 0.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;

  ${({ theme, error }) => {
    return css`
      border-bottom: 0.125rem solid
        ${error
          ? theme.colors.variants.dangerLight
          : rgba(theme.colors.variants.neutralDark2, 0.2)};
    `
  }}
`

interface ContainerProps {
  error: boolean
  isDirty: boolean
}
