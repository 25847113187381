import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Interactive } from './Interactive'
import { Props as PoiProps } from './Poi'

export interface Props {
  googleMapsURL?: string
  image?: ImageProps
  label?: string
  languageCode: string
  poiList?: PoiProps[]
  sectionID?: string
  title?: string
}

export const Map = memo(function Map({
  googleMapsURL,
  image,
  label,
  languageCode,
  poiList,
  sectionID,
  title,
}: Props) {
  const [headRef, isHeadVisible] = useInView({ threshold: 0.5 })

  return (
    <Container id={`section-${sectionID}`}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head className={isHeadVisible ? 'visible' : undefined} ref={headRef}>
          <FadeInUp>
            <IconLogo />
          </FadeInUp>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}
          {title ? (
            <FadeInUp>
              <Title>
                {title}
                <Line />
              </Title>
            </FadeInUp>
          ) : null}
        </Head>

        <Media lessThan="desktopSmall">
          <LazyLoadComponent>
            <MapImage
              alt="Albatros Lignano"
              src="/map-mobile.webp"
              width="800"
              height="1320"
            />
          </LazyLoadComponent>
        </Media>

        <Media greaterThanOrEqual="desktopSmall">
          <Interactive image={image} poiList={poiList} />
        </Media>
        {googleMapsURL ? (
          <FadeInUp>
            <CTA
              label={useVocabularyData('open-in-google-maps', languageCode)}
              rel="noopener"
              target="_blank"
              URL={googleMapsURL}
            />
          </FadeInUp>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 0 10.556vw 8.75rem;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32.125rem;
    background: white;
  }

  @media (max-width: 1199px) {
    padding: 0 1.875rem 4.375rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};

    &:before {
      content: none;
    }
  }

  @media (max-width: 1023px) {
    padding: 0 0 4.375rem;
  }

  @media (max-height: 900px) {
    &:before {
      height: calc(35.555vh + 12.125rem);
    }
  }
`

const Head = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding: 6.25rem 17.5vw 5.375rem;
  position: relative;
  overflow: hidden;
  &.visible {
    > div {
      &:before {
        bottom: 0;
      }
    }
  }

  svg {
    width: 5rem;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 2.25rem;
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 2.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  margin: 0 auto;

  > div {
    margin: 1.25rem auto 0;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1rem;
    }
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
  &:after {
    top: 0.5625rem;
  }
  svg {
    position: relative;
    top: auto;
    right: auto;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};

    &:after {
      right: 0;
    }
    svg {
      transform: translateX(0.375rem);
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }

  @media (max-width: 1023px) {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};

    &:after {
      right: 0;
    }
    svg {
      transform: translateX(0.375rem);
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }
`

const MapImage = styled.img`
  width: 100%;
  height: auto;
`
