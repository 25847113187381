import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Check, Error } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import { memo, useEffect, useState } from 'react'

export interface Props {
  languageCode: string
  text?: string
  title: string
  type?: 'confirm' | 'error'
}

export const FormMessages = memo(function FormMessages({
  languageCode,
  text,
  title,
  type = 'confirm',
}: Props) {
  const [_locked, setLocked] = useLockedBody()
  const [openMessageModal, setMessageModalOpen] = useState(false)
  const [buttonCloseVisible, setButtonClose] = useState(true)

  useEffect(() => {
    setButtonClose(true)
    setLocked(true)

    if (buttonCloseVisible) {
      setMessageModalOpen(true)
    }
  }, [buttonCloseVisible])

  return (
    <Container
      className={`confirmation-message${openMessageModal ? ' opened' : ''}`}
      onClick={() => {
        setMessageModalOpen(false)
      }}
    >
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <ModalClose
          languageCode={languageCode}
          variant="compact"
          onClick={() => {
            setMessageModalOpen(false)
            setLocked(false)
          }}
        />
        <SVG className={type === 'error' ? 'error' : undefined} dial={5}>
          {type === 'error' ? <Error /> : <Check />}
        </SVG>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        {text ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.3s;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.opened {
    opacity: 1;
    visibility: visible;
    z-index: 99;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Wrapper = styled.div`
  width: calc(100% - 3.75rem);
  max-width: 43.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: default;
  padding: 4.625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const SVG = styled(FlexBox)`
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  border-radius: 50%;
  margin: 0 auto 1.5625rem;

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;

  br {
    content: ' ';
    display: inline;
    padding: 0 0.3125rem;
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
`
