import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { IconLogo, StoriaAlbatros } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  sectionID?: string
  title?: string
}

export const History = memo(function History({
  description,
  image,
  label,
  sectionID,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container dial={2} row wrap id={`section-${sectionID}`} tag="section">
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual="desktopSmall">
          <LeftSide>
            {label ? (
              <FadeInUp>
                <Label>{label}</Label>
              </FadeInUp>
            ) : null}
            {title ? (
              <FadeInUp>
                <Title>
                  {title}
                  <Line />
                </Title>
              </FadeInUp>
            ) : null}
            <ImgWrap>
              {image ? (
                <LazyLoadComponent>
                  <Image {...image} />
                </LazyLoadComponent>
              ) : null}
            </ImgWrap>
          </LeftSide>
          <RightSide>
            <FadeInUp>
              <StoriaAlbatros />
            </FadeInUp>
            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
            <IconLogo />
          </RightSide>
        </Media>
        <Media lessThan="desktopSmall">
          <FadeInUp>
            <StoriaAlbatros />
          </FadeInUp>
          {label ? (
            <FadeInUp>
              <Label>{label}</Label>
            </FadeInUp>
          ) : null}

          {title ? (
            <FadeInUp>
              <Title>
                {title}
                <Line />
              </Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin: 14.375rem 0 12.5rem;
  padding-top: 9.75rem;
  text-align: left;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    width: 100%;
    height: 27.778vh;
  }

  > div {
    display: flex;
  }

  @media (max-width: 1199px) {
    text-align: center;
    margin: 8.125rem 0 7.5rem;
    padding: 3.75rem 1.875rem;

    > div {
      display: block !important;
      text-align: center;

      svg {
        width: 15rem;
        height: 15rem;
        margin-bottom: 2.25rem;
      }
    }

    &:before {
      content: none;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding-left: 10.556vw;

  @media (max-width: 1199px) {
    padding: 0;
  }
`

const ImgWrap = styled.div`
  position: relative;
  width: 34.5rem;
  height: 77.778vh;
  max-height: 43.75rem;
  margin-top: 7.5rem;

  picture {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.25s ease-in-out !important;
    }
  }
`

const RightSide = styled.div`
  height: 100%;
  width: 50%;
  padding: 0 10.556vw 8.333vw 7.5rem;

  @media (max-width: 1199px) {
    padding: 0 0 0 1.875rem;
  }

  svg {
    width: 13.0625rem;
    height: 13.0625rem;
  }

  > svg {
    position: absolute;
    bottom: 0;
    left: calc(50% + 8.333vw);
    margin-top: auto;
    z-index: 1;
    width: 11.75rem;
    height: 9rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  text-align: left;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 2.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
    text-align: center;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  max-width: 15rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  text-align: left;

  > div {
    margin-top: 1.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
    max-width: none;
    text-align: center;

    > div {
      margin: 1rem auto 0;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin: 6.5625rem auto 0;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 2.25rem;
  }
`
