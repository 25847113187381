import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  name: string
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    { name, placeholder, required, value, onBlur, onChange, onFocus },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    )
  }),
)
Textarea.displayName = 'Textarea'

const Container = styled.textarea`
  width: 100%;
  height: 6.25rem;
  border: 0.125rem solid ${rgba(theme.colors.variants.neutralDark2, 0.2)};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.1875rem;
  margin-top: 1.25rem;
  padding: 1.375rem 1.5625rem;
  resize: none;

  &::-webkit-input-placeholder {
    color: ${theme.colors.variants.neutralDark2};
    opacity: 1;
  }
  &::-moz-placeholder {
    color: ${theme.colors.variants.neutralDark2};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    color: ${theme.colors.variants.neutralDark2};
    opacity: 1;
  }
  &::placeholder {
    color: ${theme.colors.variants.neutralDark2};
    opacity: 1;
  }
`
