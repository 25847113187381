import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  image?: ImageProps
  poiList?: PoiProps[]
}

export const Interactive = memo(function Interactive({
  poiList,
  image,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      </Wrapper>

      {poiList
        ? poiList.map((item, index) => <Poi key={index} {...item} />)
        : null}
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
`
